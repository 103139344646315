.page_title {
  color: #fff;
  font-family: Mulish;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 76.923% */
  letter-spacing: 0.2px;
  margin-bottom: 46px;
}

.bg-color {
  background: #10101c;
}

.total_transaction {
  background: #222235 url("../images/Maskgroup.png") no-repeat right;
  height: 233px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-bottom: 62px;
}

.bg_one,
.bg_two {
  border-radius: 8px;
  background: rgba(34, 34, 53, 0.23);
}

.bg_one {
  height: 221px;
  top: 26px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 96%;
  z-index: 2;
}

.bg_two {
  height: 204px;
  top: 59px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  z-index: 1;
}

.total_transaction_text {
  color: #fff;
  text-align: center;
  font-family: Mulish;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.2px;
}

.total_transaction_price {
  color: #b22e2e;
  text-align: center;
  font-family: Mulish;
  font-size: 50px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.2px;
}

.search
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input-affix-wrapper
  .ant-input {
  background: #10101c;
  color: white;
}

.search
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input-affix-wrapper {
  padding: 16px 10px;
  border-radius: 4px;
  border: 1px solid #222235;
  background: #10101c;
}

.search
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input-affix-wrapper:focus {
  box-shadow: 0 0 0 2px #10101c;
}

.search
  .ant-form-item-control
  .ant-form-item-control-input
  .ant-form-item-control-input-content
  .ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 2px #10101c;
}

.search_button {
  color: #fff !important;
  font-family: Mulish;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 800 !important;
  line-height: normal !important;
  border-radius: 4px !important;
  background: #b22e2e !important;
  border-color: #b22e2e !important;
  padding: 13px 52px !important;
  height: auto !important;
}
.transaction_scrollbar::-webkit-scrollbar {
  width: 0.5rem;
}

.transaction_scrollbar::-webkit-scrollbar {
  height: 0.375rem;
}

.transaction_scrollbar::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  height: 5px;
}

.transaction_scrollbar::-webkit-scrollbar-thumb {
  border-radius: 0.25rem;
}

.transaction_scrollbar::-webkit-scrollbar-track {
  background-color: rgb(241 245 249);
}

.transaction_scrollbar::-webkit-scrollbar-track {
  border-radius: 0.25rem;
}

/*.shadowBorder {*/
/*    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .2);*/
/*    box-shadow: 0 0 10px rgba(0, 0, 0, .2);*/
/*}*/

@media (min-width: 768px) and (max-width: 991.99px) {
  .transaction_card {
    padding: 15px !important;
  }

  .transaction_hash,
  .from_hash {
    margin-top: 5px;
  }

  .payment_page {
    padding-left: 4.2rem !important;
  }
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .transaction_hash,
  .from_hash {
    margin-top: 5px;
  }
}

@media (min-width: 992px) and (max-width: 1399.99px) {
  .payment_page {
    padding-left: 4.8rem !important;
  }
}

@media (min-width: 1400px) {
  .payment_page {
    padding-left: 4.8rem !important;
  }
}

@media (max-width: 991.99px) {
  .total_transaction {
    background-position: center;
  }

  .nfts_section {
    flex-wrap: wrap;
  }

  .nfts_card_main {
    margin-right: 0 !important;
    width: 100% !important;
    margin-bottom: 50px;
  }

  .payment_page {
    margin-top: 56px !important;
  }
}

@media (max-width: 575.99px) {
  .transaction_card {
    padding: 18px !important;
  }

  .transaction_hash,
  .from_hash {
    margin-top: 5px;
  }
}

/*@media only screen and (max-width: 600px) {*/
/*    .paymentMethodCard {*/
/*        flex-direction: column;*/
/*    }*/

/*    .paymentMethodCardContent {*/
/*        display: flex;*/
/*        justify-content: space-between;*/
/*    }*/

/*    .withdrawRequest {*/
/*        margin-top: 20px;*/
/*    }*/
/*}*/
