.videoCardBtns {
    height: 30px !important;
    width: 100% !important;
}

.video {
    object-fit: cover;
    border-radius: 10px;
    height: 100px;
    width: 100px;
}

@media only screen and (max-width: 600px) {
    .videoCardMobView {
        flex-direction: column;
        text-align: center;
    }

    .videoCardMobAlignment {
        margin-top: 10px;
        margin-right: 0px !important;
        justify-content: center;
    }
}
