.whiteSearchStyle {
    border-radius: 10px !important;
    color: #000 !important;
    border: none !important;
    padding-left: 0px !important;
}

.whiteSearchStyle:focus {
    box-shadow: none !important;
}

.sendBtn {
    width: 50px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}

.liveChatHeadView{
    display: flex;
}

@media only screen and (max-width: 600px) {
    .liveChatHeadView{
        display: block;
    }
    .liveChat{
        margin-bottom: 5px;
    }
}