.statisticsCardStyle{
    border-radius: 20px;
    height: 140px;
}

.statisticsIconView{
    /* background-color: #232645; */
    width: 60px;
    height: 60px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}