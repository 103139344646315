.webtable {
    display: block;

}

.mobtable {
    display: none;
}

.dropdowmStyle {
    background-color: #F1F0F0 !important;
    border: none !important;
    color: #9FA2B4 !important
}

.dropdowmStyle:hover {
    color: #9FA2B4 !important;
}

.optionsMobView {
    display: none;
}

.optionsWebView {
    display: block;
}

@media only screen and (max-width: 600px) {
    .webtable {
        display: none;
    }

    .mobtable {
        display: block;
    }

    .optionsMobView {
        display: block;
    }

    .optionsWebView {
        display: none;
    }
}